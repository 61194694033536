<template>
  <default-page :page-no="pageId"/>
</template>

<script>

import DefaultPage from "@/pages/defaultPage";

export default {
  components: {DefaultPage},
  data() {
    return {
      pageId: 16,
    };
  },
}
</script>
