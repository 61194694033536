<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <header-image :page-no="pageNo" :base-url="baseUrl" :bg-image="bgImage" :key="bgImage"/>
        <h3>Manage Page Content</h3>
        <div class="row">
          <div class="col-12">
            <div class="card card-product">
              <div class="card-body">
                <form class="ui form">
                  <div class="field">
                    <div class="fields">
                      <div class="four wide field">
                        <label>Heading</label>
                        <input type="text" name="heading" placeholder="Heading" v-model="heading">
                      </div>
                      <div class="twelve wide field">
                        <label>Tagline</label>
                        <input type="text" name="tagline" placeholder="tagline" v-model="tagline">
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label>Page Content</label>
                    <editor :api-key="tinyKey" :init="tinyMyceConfig" v-model="content"/>
<!--
                    <ckeditor :editor="editor" v-model="content" @ready="setPage" :config="editorConfig"></ckeditor>
                    -->
                  </div>
                </form>
              </div>
              <div class="card-footer">
                <button class="btn btn-success btn-round pull-right text-right" @click="editContent">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import HeaderImage from "@/components/HeaderImage";
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'defaultPage',
  components: {HeaderImage, 'editor': Editor},
  props: {
    pageNo: Number,
  },
  data() {
    return {
      posts: [],
      baseUrl: '',
      more: 10,
      pageId: this.pageNo,
      bgImage: '',
      heading: '',
      fontSize: '',
      fontColor: '',
      created: '',
      id: null,
      commentStatus: 'disabled',
      imageType: 'upload',
      imageName: '',
      content: '',
      tagline: '',
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
      tinyMyceConfig: {
        skin: "bootstrap",
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: "h1 h2 | styles | bold italic strikethrough blockquote bullist numlist backcolor | link image media | " +
            "undo redo| alignleft aligncenter alignright alignjustify | removeformat help",
        menubar: false,
        height: 600,
        content_style: "p { line-height: 1; margin: 0.5rem 0; }",
        setup: (editor) => {
          // Apply the focus effect
          editor.on("init", () => {
            editor.getContainer().style.transition =
                "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out";
          });
          editor.on("focus", () => {
            (editor.getContainer().style.boxShadow = "0 0 0 .2rem rgba(0, 123, 255, .25)"),
                (editor.getContainer().style.borderColor = "#80bdff");
          });
          editor.on("blur", () => {
            (editor.getContainer().style.boxShadow = ""),
                (editor.getContainer().style.borderColor = "");
          });
        },
      },
      tinyKey: this.$store.state.tinyKey
    };
  },
  mounted: function(){
    let app = this
    this.refresh();
  },

  methods: {
    ...mapActions(["getPage", "updateContent"]),
    refresh(){
      this.setPage();
      console.log('parent refreshing')
    },

    reset(){
      this.heading = '';
      this.id = null;
      this.fontColor = '';
      this.fontSize = '';
      this.created = '';
      this.imageName = '';
    },

    async editContent(){
      let app = this;
      window.swal({
        title: "Updating page...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let payload = {
        heading: app.heading,
        tagline: app.tagline,
        content: app.content,
        id: app.pageId,
      };

      let response = await this.updateContent(payload)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {

        swal("Success!", response.message, "success");
        app.refresh();
      }
    },

    async setPage() {
      let app = this;
      let payload = {
        id: app.pageId
      };
      let response = await this.getPage(payload)
      //console.log("res: " +JSON.stringify(response))
      app.bgImage = response.message.bgImage;
      app.baseUrl = response.url;
      app.heading = response.message.heading;
      app.content = response.message.content;
      app.tagline = response.message.tagline;
    },

    modal: function (modal, data = null ){
      if (modal === 'postFromGallery') $('#addPost').modal('hide');
      $('#'+modal).modal('show');

      if (data) {
        this.id = data.id;
        this.heading = data.heading;
        this.fontColor = data.fontColor;
        this.fontSize = data.fontSize;
        this.created = data.created;
      }
    },
  },
}
</script>
