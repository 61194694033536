import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import VueCookies from 'vue-cookies'
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// semantic ui
import SuiVue from "semantic-ui-vue";
import "semantic-ui-css/semantic.min.css";

Vue.use(VueSweetalert2);

Vue.use(VueCookies);
Vue.use(SuiVue);

Vue.$cookies.config('7d');

// ckeditor
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );

Vue.config.productionTip = false

new Vue({
  router: router,
  store,
  render: h => h(App)
}).$mount('#app')
