<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <header-image :page-no="pageId" :base-url="baseUrl" :bg-image="bgImage" :key="bgImage"/>
        <h3>Manage Courses</h3>
        <div class="row">
          <div class="col-md-7">
            <div class="card">
              <div class="card-header card-header-icon card-header-rose">
                <div class="card-icon">
                  <i class="material-icons">perm_identity</i>
                </div>
                <h4 class="card-title">Courses</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Created</th>
                      <th class="text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="course in courses" :key="course.id">
                      <td v-text="course.name"></td>
                      <td v-text="course.created"></td>
                      <td class="td-actions text-center">
                        <div class="btn btn-sm btn-danger" @click="modal('updateCourse', course)">
                          <i class="material-icons">edit</i>
                        </div>
                      </td>
                      <td class="td-actions text-center">
                        <div class="btn btn-sm btn-danger" @click="deleteCourse(course.id)">
                          <i class="material-icons">delete</i>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="card">
              <div class="card-header card-header-icon card-header-rose">
                <div class="card-icon">
                  <i class="material-icons">how_to_reg</i>
                </div>
                <h4 class="card-title">Add Course</h4>
              </div>
              <div class="card-body">
                <div class="ui form">
                  <div class="equal width fields">
                    <div class="field">
                      <label>Full Names</label>
                      <div class="ui fluid input">
                        <input type="text" placeholder="" v-model="name">
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-rose pull-right" @click="addCourse">Add</button>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal | Choose from gallery -->
    <div class="ui modal mini" id="updateCourse">
      <i class="close icon"></i>
      <div class="header">
        Update Course
      </div>
      <div class="content">
        <div class="ui form">
          <div class="field">
            <label>Course Name</label>
            <div class="ui fluid input">
              <input type="text" placeholder="" v-model="name">
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Cancel
        </div>
        <div class="ui green approve button" @click="updateCourse">
          Update
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import HeaderImage from "@/components/HeaderImage";

export default {
  name: 'defaultPage',
  components: {HeaderImage},
  data() {
    return {
      baseUrl: '',
      pageId: 9,
      bgImage: '',
      courses: [],
      name: ''
    };
  },
  mounted: function(){
    let app = this
    this.refresh();
  },

  methods: {
    ...mapActions(["getPage", "updateContent", "postJson"]),
    refresh(){
      this.setPage();
      this.getCourses();
      console.log('parent refreshing')
    },

    reset(){
    },

    async setPage() {
      let app = this;
      let payload = {
        id: app.pageId
      };
      let response = await this.getPage(payload)
      //console.log("res: " +JSON.stringify(response))
      app.bgImage = response.message.bgImage;
      app.baseUrl = response.url;
    },

    modal: function (modal, data = null ){
      if (modal === 'postFromGallery') $('#addPost').modal('hide');
      $('#'+modal).modal('show');

      if (modal === 'updateCourse') {
        this.id = data.id
        this.name = data.name
        return
      }
      if (data) {
        this.id = data.id;
        this.heading = data.heading;
        this.fontColor = data.fontColor;
        this.fontSize = data.fontSize;
        this.created = data.created;
      }
    },

    async deleteCourse (id) {
      let app = this;
      let dataObject = {
        id: id,
        route: 'courses/delete/',
      };

      let response = await this.postJson(dataObject)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {

        swal("Success!", response.message, "success");
        app.reset();
        await app.getCourses();
      }
    },

    async getCourses () {
      let app = this;
      let dataObject = {
        route: 'courses/view/',
      };

      let response = await this.postJson(dataObject)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {
        app.courses = response.message;
      }
    },

    async addCourse() {
      let app = this;
      let dataObject = {
        name: app.name,
        route: 'courses/add/',
      };

      let response = await this.postJson(dataObject)
      if (response.status !== 201) {
        swal("Error!", response.message, "error");
      } else {

        swal("Success!", response.message, "success");
        app.reset();
        await app.getCourses();
      }
    },

    async updateCourse () {
      let app = this;
      let dataObject = {
        id: app.id,
        name: app.name,
        route: 'courses/update/',
      };

      let response = await this.postJson(dataObject)
      if (response.status !== 200) {
        swal("Error!", response.message, "error");
      } else {

        swal("Success!", response.message, "success");
        app.reset();
        await app.getCourses();
      }
    },
  },
}
</script>
