<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header card-header-icon card-header-rose">
                <div class="card-icon">
                  <i class="material-icons">room_preferences</i>
                </div>
                <h4 class="card-title">Edit Menu
                </h4>
              </div>
              <div class="card-body">
                <div class="ui form">
                  <div class="equal width fields">
                    <div class="field">
                      <label>Font Size</label>
                      <div class="ui fluid input">
                        <input type="number" placeholder="" v-model="fontSize">
                      </div>
                    </div>
                  </div>
                  <div class="equal width fields">
                    <div class="field">
                      <label>Font Color</label>
                      <div class="ui fluid input">
                        <input type="color" placeholder="" v-model="fontColor" style="height: 38px">
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-rose pull-right" @click="updateMenu">Update Menu</button>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-header card-header-rose card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">assignment</i>
                </div>
                <h4 class="card-title">Set No of Posts per page</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Page Name</th>
                      <th class="text-center">Comment</th>
                      <th class="text-center">No of Posts</th>
                      <th class="text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="page in pages">
                      <td v-text="page.name"></td>
                      <td>
                        <select class="custom-select" id="exampleFormControlSelect1" v-model="page.comment" :id="'comment-'+page.id">
                          <option value="ENABLED">ENABLED</option>
                          <option value="DISABLED">DISABLED</option>
                        </select>
                      </td>
                      <td class="text-center" v-text="page.postPerPage" contenteditable="true" :id="'pageId-'+page.id"></td>
                      <td class="td-actions text-center">
                        <div class="btn btn-sm btn-success" @click="updatePostNo(page.id)">
                          Click to Save
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import HeaderImage from "@/components/HeaderImage";

export default {
  components: {HeaderImage},
  data() {
    return {
      fontSize: '',
      fontColor: '',
      pages: [],
      postPerPage: '',
    };
  },
  mounted: function(){
    this.refresh();
  },

  methods: {
    ...mapActions(["postJson"]),
    refresh(){
      this.getPages();
    },

    reset(){
      this.fullName = '';
      this.msisdn = '';
      this.email = '';
      this.password = '';
      this.password1 = '';
      this.postPerPage = '';
    },

    async getPages() {
      let app = this;
      let dataObject = {
        route: 'page/view/',
      };
      let response = await this.postJson(dataObject)
      app.pages = response.message;
    },

    async updateMenu() {
      let app = this;
      let dataObject = {
        fontSize: app.fontSize,
        fontColor: app.fontColor,
        route: 'page/update/',
      };
      let response = await this.postJson(dataObject)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.reset();
        app.refresh();
      }
    },

    async updatePostNo(id) {
      let app = this;
      let post = document.getElementById("pageId-"+id).innerText;
      let comment = document.getElementById("comment-"+id).value;
      let dataObject = {
        postPerPage: post,
        comment: comment,
        id: id,
        route: 'page/update/',
      };
      let response = await this.postJson(dataObject)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.reset();
        app.refresh();
      }
    },
  },
}
</script>
