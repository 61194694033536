import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import axios from 'axios';
import router from '../router/index'

Vue.use(Vuex);

const state = {
    base: process.env.BASE_URL,
    api: process.env.VUE_APP_API,
    tinyKey: process.env.VUE_APP_TINYMYCE_KEY,
    auth: null,
    user: null,
};
const getters = {
    isAuthenticated: state => !!state.auth,
    StateToken: state => state.auth,
    StateApi: state => state.api,
};
const actions = {
    async LogOut({commit}){
        commit('setLogOut')
    },
    async changePass({commit, getters}, payload) {
        payload.auth = getters.StateToken
        let res = {}
        await axios.post(getters.StateApi + 'user/password/change/', payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.message = response.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async getPage({commit, getters}, payload) {
        payload.auth = getters.StateToken
        let res = {}
        await axios.post(getters.StateApi + 'page/view/', payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.url = response.data.url
                res.message = response.data.message[0]
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async getFiles({commit, getters}, payload) {
        payload.auth = getters.StateToken
        let res = {}
        await axios.post(getters.StateApi + 'user/list-files/', payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.message = response.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async updatePageGallery({commit, getters}, payload) {
        payload.auth = getters.StateToken
        let res = {}
        await axios.post(getters.StateApi + 'page/gallery/', payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.message = response.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async updateContent({commit, getters}, payload) {
        payload.auth = getters.StateToken
        let res = {}
        await axios.post(getters.StateApi + 'page/update/', payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.message = response.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async LogIn({commit, getters}, payload) {
        let res = {}
        await axios.post(getters.StateApi + 'user/login/', payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.message = 'Logged in successful'
                await commit('setAuth', response.data.message.auth)
                await commit('setUser', response.data.message.user)
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async getPost({commit, getters}, payload) {
        payload.auth = getters.StateToken
        let res = {}
        await axios.post(getters.StateApi + 'post/view/', payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.url = response.data.url
                res.message = response.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async removePost({commit, getters}, payload) {
        payload.auth = getters.StateToken
        let res = {}
        await axios.post(getters.StateApi + 'post/delete/', payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.message = response.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async removeFile({commit, getters}, payload) {
        payload.auth = getters.StateToken
        let res = {}
        await axios.post(getters.StateApi + 'user/delete-file/', payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.message = response.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async postJson({commit, getters}, payload) {
        let res = {}
        payload.auth = getters.StateToken
        await axios.post(getters.StateApi + payload.route, payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.message = response.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
    async postFormData({commit, getters}, payload) {
        let res = {}
        payload.append('auth', getters.StateToken)
        await axios.post(getters.StateApi + payload.get('route'), payload, {headers: {
                'Content-Type': "application/json"
            }})
            .then(async function (response) {
                //console.log("success: " +JSON.stringify(response))
                res.status = response.data.status
                res.message = response.data.message
            })
            .catch(function(error) {
                console.log("error: " +JSON.stringify(error.response))
                res.status = error.response.data.status
                res.message = error.response.data.message
            })

        return res
    },
};
const mutations = {
    setUser(state, payload){
        state.user = payload
    },
    setAuth(state, token){
        state.auth = token
    },
    setLogOut(state){
        state.auth = null
        state.user = null
        router.push({name: 'login'});
    },
};

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  plugins: [createPersistedState()]
});
