<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="float-left">
        <ul>
          <li>
            <a href="https://www.iampapajan.com">
              iampapajan
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright float-right">
        &copy;{{ year }}, made with <i class="material-icons">favorite</i> by
        <a href="https://www.halo-technology.co.ke" target="_blank">Halo Technology</a> for a better web.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer",
  data:function() {
    return {
      year: new Date().getFullYear(),
    }
  }
}
</script>

<style scoped>

</style>
