<template>
  <default-page-header :page-no="pageId"/>
</template>

<script>

import DefaultPageHeader from "@/pages/defaultPageHeader";

export default {
  components: {DefaultPageHeader},
  data() {
    return {
      pageId: 12,
    };
  },
}
</script>
