<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card ">
          <div class="card-header card-header-success card-header-icon">
            <div class="card-icon">
              <i class="material-icons">wallpaper</i>
            </div>
            <h4 class="card-title">Edit Header Image</h4>
          </div>
          <div class="card-body ">
            <form enctype="multipart/form-data" id="upload">
              <div class="row">
                <div class="col-12">

                  <div class="fileinput fileinput-new text-center" data-provides="fileinput">
                    <div class="fileinput-new">
                      <img class="front-page-banner bg-image" :src="baseUrl + 'headers/' + bgImage" alt="..">
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="d-flex justify-content-between">
                          <span class="btn btn-primary btn-round btn-file">
                            <i class="material-icons">file_upload</i>
                            <span class="fileinput-new">Upload from local</span>
                            <span class="fileinput-exists">Change</span>
                            <input type="file" name="importfile" id="importfile" />
                          </span>
                      <a href="#pablo" class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput"><i class="fa fa-times"></i> Remove</a>
                      <button type="button" class="btn btn-primary btn-round" @click="modal('bgFromGallery')">
                        <i class="material-icons">collections</i> Choose from gallery
                      </button>
                      <button type="button" class="btn btn-success btn-round" @click="editPage">
                        <i class="material-icons">thumb_up_alt</i> Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal | Choose from gallery -->
    <div class="ui modal" id="bgFromGallery">
      <i class="close icon"></i>
      <div class="header">
        Choose Image
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-4 py-2" v-for="img in images.slice(0, -2)" @click="editPageGallery(img)">
            <img class="img-fluid" :src="baseUrl + 'headers/' + img">
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Cancel
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "HeaderImage",
  props: {
    baseUrl: String,
    bgImage: String,
    pageNo: Number,
  },
  data() {
    return {
      images: [],
      pageId: this.pageNo,
    }
  },
  mounted() {
    this.setFiles()
  },
  methods: {
    ...mapActions(["updateContent", "getFiles", "updatePageGallery"]),

    modal: function (modal, data = null ){
      if (modal === 'postFromGallery') $('#addPost').modal('hide');
      $('#'+modal).modal('show');

      if (data) {
        this.id = data.id;
        this.heading = data.heading;
        this.fontColor = data.fontColor;
        this.fontSize = data.fontSize;
        this.created = data.created;
      }
    },

    refresh(){
      this.setFiles();
      console.log('refreshing parent')
      this.$parent.refresh()
    },

    async editPage (){
      window.swal({
        title: "Uploading data...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let app = this;
      let formData = new FormData();
      formData.append('id', app.pageId);

      // Attach file
      formData.append('image', $('input[type=file]')[0].files[0]);

      let response = await this.updateContent(formData)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {

        swal("Success!", response.message, "success");
        app.refresh();

        $('.fileinput').fileinput('reset');
      }
    },

    async setFiles() {
      let app = this;
      let payload = {
        type: 'headers',
      };
      let response = await this.getFiles(payload)
      //console.log("res: " +JSON.stringify(response))
      app.images = response.message;
    },

    async editPageGallery(imageName){
      let app = this;
      window.swal({
        title: "Editing page...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      console.log("pageId: " + this.pageId)
      let payload = {
        id: app.pageId,
        imageName: imageName,
      };
      console.log("payload image: " + JSON.stringify(payload))
      let response = await this.updatePageGallery(payload)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.refresh();

        console.log('hiding bg gallery')
        $('#bgFromGallery').modal('hide');
        $('#bgFromGallery').modal('hide');
        $('#bgFromGallery').modal('hide');
      }

    },
  }
}
</script>

<style scoped>

</style>
