<template>
  <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top " id="navigation-example">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-minimize">
          <button id="minimizeSidebar" class="btn btn-just-icon btn-white btn-fab btn-round">
            <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
            <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
          </button>
        </div>
        <a class="navbar-brand" href="javascript:void(0)">{{ this.$route.meta.name }} Page</a>
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" data-target="#navigation-example">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="material-icons">person</i>
              <p class="d-lg-none d-md-block">
                Account
              </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" href="#" @click="modal('changePassword')">Change Password</a>
            </div>
          </li>
          <li class="nav-item" @click="logout">
            <a class="nav-link" href="#">
              <i class="material-icons">logout</i>
              <p class="d-lg-none d-md-block">
                Logout
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- Modal | Change Password -->
    <div class="ui modal" id="changePassword">
      <i class="close icon"></i>
      <div class="header">
        Change Password
      </div>
      <div class="content">
        <div class="ui grid">
          <div class="column">
            <div class="ui form">
              <div class="equal width fields">
                <div class="field">
                  <label>Old Password</label>
                  <div class="ui fluid input">
                    <input type="password" placeholder="" v-model="oldPassword">
                  </div>
                </div>
              </div>
              <div class="equal width fields">
                <div class="field">
                  <label>New Password</label>
                  <div class="ui fluid input">
                    <input type="password" placeholder="" v-model="newPassword">
                  </div>
                </div>
                <div class="field">
                  <label>Confirm Password</label>
                  <div class="ui fluid input">
                    <input type="password" placeholder="" v-model="confirmPassword">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Cancel
        </div>
        <div class="ui right labeled icon button" v-on:click="changePassword">
          Submit
          <i class="checkmark icon"></i>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "navbar",
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  },
  methods: {
    ...mapActions(["LogOut", "changePass"]),

    reset(){
      this.oldPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
    },

    async logout () {

      await this.LogOut();
    },

    async changePassword () {
      let app = this;
      if (app.newPassword !== app.confirmPassword) {
        swal("Error!", "Password does not match", "error");
        return;
      }

      let payload = {
        password: app.oldPassword,
        password1: app.newPassword,
        confirmPassword: app.confirmPassword
      };

      let response = await this.changePass(payload)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {

        swal("Success!", response.message, "success");
        app.reset();
        $('#changePassword').modal('hide');
      }
    },
    modal: function (modal){
      $('#'+modal).modal('show');
    },
  },
}
</script>

<style scoped>

</style>
