<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header card-header-primary card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">assignment</i>
                </div>
                <h4 class="card-title">Registration</h4>
              </div>
              <div class="card-body">
                <div class="toolbar">
                  <!--        Here you can write extra buttons/actions for the toolbar              -->
                </div>
                <div class="material-datatables">
                  <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                    <thead>
                    <tr>
                      <th>Full Names</th>
                      <th>Email</th>
                      <th>Phone No</th>
                      <th>Course</th>
                      <th>Date</th>
                    </tr>
                    </thead>
                    <tfoot>
                    <tr>
                      <th>Full Names</th>
                      <th>Email</th>
                      <th>Phone No</th>
                      <th>Course</th>
                      <th>Created date</th>
                    </tr>
                    </tfoot>
                    <tbody>
                    <tr v-for="comment in comments">
                      <td v-text="comment.firstName + ' ' + comment.lastName"></td>
                      <td v-text="comment.email"></td>
                      <td v-text="comment.msisdn"></td>
                      <td v-text="comment.course"></td>
                      <td v-text="comment.created"></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- end content-->
            </div>
            <!--  end card  -->
          </div>
          <!-- end col-md-12 -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import HeaderImage from "@/components/HeaderImage";

export default {
  components: {HeaderImage},
  data() {
    return {
      comments: [],
    };
  },
  mounted: function(){
    this.refresh();
  },

  methods: {
    ...mapActions(["postJson"]),
    refresh(){
      this.setRegistration();
    },

    async initTable (){

      setTimeout(function (){
        console.log('get image after 2s');


        // If table is initialized
        $('#datatables').DataTable().fnDestroy();

        $('#datatables').DataTable();

        // $('#datatables').DataTable().fnReloadAjax();
      }, 1500)
    },

    async setRegistration() {
      let app = this;
      let dataObject = {
        route: 'register/view/',
      };
      let response = await this.postJson(dataObject)
      app.comments = response.message;
      // console.log('page: '+JSON.stringify(response.message));
      await app.initTable();
    },
  },
}
</script>
