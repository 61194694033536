<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <header-image :page-no="pageNo" :base-url="baseUrl" :bg-image="bgImage" :key="bgImage"/>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import HeaderImage from "@/components/HeaderImage";

export default {
  name: 'defaultPageHeader',
  components: {HeaderImage},
  props: {
    pageNo: Number,
  },
  data() {
    return {
      baseUrl: '',
      pageId: this.pageNo,
      bgImage: '',
    };
  },
  mounted: function(){
    let app = this
    this.refresh();
  },

  methods: {
    ...mapActions(["getPage"]),
    refresh(){
      this.setPage();
      console.log('parent refreshing')
    },

    async setPage() {
      let app = this;
      let payload = {
        id: app.pageId
      };
      let response = await this.getPage(payload)
      //console.log("res: " +JSON.stringify(response))
      app.bgImage = response.message.bgImage;
      app.baseUrl = response.url;
    },
  },
}
</script>
