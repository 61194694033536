<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <h3>Manage Files</h3>
        <label>Choose File Type</label>
        <select class="ui dropdown form-control search" data-width="auto" v-model="type">
          <option value="headers">Headers</option>
          <option value="blogs">Blogs</option>
          <option value="images">Images</option>
          <option value="others">Others</option>
        </select>
        <button class="btn btn-behance" @click="modal('addFile')"><i class="material-icons">add</i> Click here to Add a File</button>
        <br>
        <br>
        <div class="row">
          <div class="col-md-3 py-2 container" v-for="img in images.slice(0, -2)" style="border: solid 1px lightgray;">
            <input style="display: none" type="text" :id="img" :value="baseUrl + img"/>
            <img class="img-fluid image" style="width:100%" :src="baseUrl + img">
            <div class="middle">
              <div class="btn btn-success" :id="'id-'+img" @click="copyLink(img)">Copy link</div>
              <div class="btn btn-danger" @click="deleteFile(img)">Delete File</div>
            </div>
            <div class="text-center" style="position: absolute; bottom: 0">{{ img }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal | Add File -->
    <div class="ui modal" id="addFile">
      <i class="close icon"></i>
      <div class="header">
        Add File
      </div>
      <div class="content">
        <div class="ui grid">
          <div class="column">
            <div class="ui form">
              <div class="field">
                <label>Choose File Type</label>
                <select class="ui dropdown form-control search" data-width="auto" v-model="type">
                  <option value="headers">Headers</option>
                  <option value="blogs">Blogs</option>
                  <option value="images">Images</option>
                  <option value="others">Others</option>
                </select>
              </div>
              <div class="equal width fields">
                <div class="field">
                  <label>Image</label>
                  <div class="ui fluid input">
                    <input type="file" id="postImage" name="postImage">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Cancel
        </div>
        <div class="ui right labeled icon button" v-on:click="addFile">
          Save
          <i class="checkmark icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import HeaderImage from "@/components/HeaderImage";

export default {
  components: {HeaderImage},
  data() {
    return {
      images: [],
      type: 'blogs',
      baseUrl: '',
      pageId: 2,
      file: false
    };
  },
  watch: {
    type(newVal, oldVal){
      if (newVal !== oldVal){
        this.setFiles();
      }
    }
  },
  mounted: function(){
    this.refresh();
  },

  methods: {
    ...mapActions(["postJson", "getFiles", "postFormData", "getPage"]),
    refresh(){
      this.setPage();
      this.setFiles();
    },

    async setFiles() {
      let app = this;
      let payload = {
        type: app.type,
      };
      app.baseUrl = app.fileUrl + app.type + '/';
      let response = await this.getFiles(payload)
      //console.log("res: " +JSON.stringify(response))
      app.images = response.message;
    },

    async setPage() {
      let app = this;
      let payload = {
        id: app.pageId
      };
      let response = await this.getPage(payload)
      //console.log("res: " +JSON.stringify(response))
      app.baseUrl = response.url + app.type + '/';
      app.fileUrl = response.url;
    },

    async addFile (){
      let app = this;

      window.swal({
        title: "Adding File...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let formData = new FormData();
      formData.append('type', app.type);
      formData.append('route', 'post/upload/');
      // Attach file
      // formData.append('image', $('input[type=file]')[0].files[0]);
      formData.append('image', document.getElementById('postImage').files[0]);

      let response = await this.postFormData(formData)
      if (response.status !== 201){
        swal("Error!", response.message, "error");
      }
      else {

        swal("Success!", response.message, "success");
        app.refresh();

        // $('.fileinput').fileinput('reset');
        $('#addFile').modal('hide');
        document.getElementById('postImage').value = '';
      }
    },

    async deleteFile(imageName) {
      let app = this;
      window.swal({
        title: "Deleting file...",
        text: "Please wait",
        imageUrl: "../assets/img/spinner.gif",
        showConfirmButton: false,
        allowOutsideClick: false
      });
      let dataObject = {
        imageName: imageName,
        type: app.type,
        route: 'user/delete-file/'
      };
      let response = await this.postJson(dataObject)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.refresh();
      }
    },

    selectImage: function (imageName){
      this.imageName = imageName;

      $('#postFromGallery').modal('hide');
      $('#addPost').modal('show');
    },

    modal: function (modal, data = null ){
      if (modal === 'postFromGallery'){
        $('#addPost').modal('hide');
        this.type = 'blogs'
      }
      if (modal === 'bgFromGallery') this.type = 'headers';
      $('#'+modal).modal('show');

      if (data) {
        this.id = data.id;
        this.heading = data.heading;
        this.fontColor = data.fontColor;
        this.fontSize = data.fontSize;
        this.created = data.created;
      }
    },

    copyLink: function (id){
      var copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);

      document.getElementById('id-'+id).innerHTML = 'Link Copied';
    }
  },
}
</script>
