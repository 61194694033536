<template>
  <div>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header card-header-icon card-header-rose">
                <div class="card-icon">
                  <i class="material-icons">perm_identity</i>
                </div>
                <h4 class="card-title">Edit Profile
                </h4>
              </div>
              <div class="card-body">
                <div class="ui form">
                  <div class="equal width fields">
                    <div class="field">
                      <label>Full Names</label>
                      <div class="ui fluid input">
                        <input type="text" placeholder="" v-model="fullName">
                      </div>
                    </div>
                  </div>
                  <div class="equal width fields">
                    <div class="field">
                      <label>Email Address</label>
                      <div class="ui fluid input">
                        <input type="text" placeholder="" v-model="email">
                      </div>
                    </div>
                  </div>
                  <div class="equal width fields">
                    <div class="field">
                      <label>Phone No</label>
                      <div class="ui fluid input">
                        <input type="text" placeholder="" v-model="msisdn">
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-rose pull-right" @click="updateProfile">Update Profile</button>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-header card-header-icon card-header-rose">
                <div class="card-icon">
                  <i class="material-icons">how_to_reg</i>
                </div>
                <h4 class="card-title">Add User
                </h4>
              </div>
              <div class="card-body">
                <div class="ui form">
                  <div class="equal width fields">
                    <div class="field">
                      <label>Full Names</label>
                      <div class="ui fluid input">
                        <input type="text" placeholder="" v-model="fullName">
                      </div>
                    </div>
                  </div>
                  <div class="equal width fields">
                    <div class="field">
                      <label>Email Address</label>
                      <div class="ui fluid input">
                        <input type="email" placeholder="" v-model="email">
                      </div>
                    </div>
                    <div class="field">
                      <label>Phone No</label>
                      <div class="ui fluid input">
                        <input type="tel" placeholder="" v-model="msisdn">
                      </div>
                    </div>
                  </div>
                  <div class="equal width fields">
                    <div class="field">
                      <label>Password</label>
                      <div class="ui fluid input">
                        <input type="password" placeholder="" v-model="password">
                      </div>
                    </div>
                    <div class="field">
                      <label>Confirm Password</label>
                      <div class="ui fluid input">
                        <input type="password" placeholder="" v-model="password1">
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-rose pull-right" @click="register">Register</button>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">assignment</i>
              </div>
              <h4 class="card-title">Users</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>Full Names</th>
                    <th>Email Address</th>
                    <th>Phone No</th>
                    <th class="text-center">Role</th>
                    <th class="text-center">Status</th>
                    <th class="text-right">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="user in users">
                    <td v-text="user.fullName"></td>
                    <td v-text="user.email"></td>
                    <td v-text="user.msisdn"></td>
                    <td class="text-center" v-show="user.permission === '1'"><div class="btn btn-sm btn-outline-success">Super User</div> </td>
                    <td class="text-center" v-show="user.permission === '0'"><div class="btn btn-sm btn-outline-primary">User</div> </td>
                    <td class="text-center" v-show="user.status === '1'"><div class="btn btn-sm btn-success">Active</div> </td>
                    <td class="text-center" v-show="user.status === '0'"><div class="btn btn-sm btn-danger">Inactive</div> </td>
                    <td class="td-actions text-right" v-show="user.status === '0'">
                      <div class="btn btn-sm btn-success" @click="activate(user.id)">
                        <i class="material-icons">thumb_up_alt</i> Activate
                      </div>
                    </td>
                    <td class="td-actions text-right" v-show="user.status === '1'">
                      <div class="btn btn-sm btn-danger" @click="deactivate(user.id)">
                        <i class="material-icons">delete</i> Deactivate
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import HeaderImage from "@/components/HeaderImage";

export default {
  components: {HeaderImage},
  data() {
    return {
      fullName: '',
      email: '',
      msisdn: '',
      password: '',
      password1: '',
      users: [],
      details: [],
      pages: [],
      postPerPage: '',
    };
  },
  mounted: function(){
    this.refresh();
  },

  methods: {
    ...mapActions(["postJson"]),
    refresh(){
      this.getUsers();
      this.getUser();
      this.getPages();
    },

    reset(){
      this.fullName = '';
      this.msisdn = '';
      this.email = '';
      this.password = '';
      this.password1 = '';
      this.postPerPage = '';
    },

    async getUsers() {
      let app = this;
      let dataObject = {
        route: 'user/view/',
      };
      let response = await this.postJson(dataObject)
      app.users = response.message;
    },

    async getUser() {
      let app = this;
      let dataObject = {
        id: 'self',
        route: 'user/view/',
      };
      let response = await this.postJson(dataObject)
      app.details = response.message;
      app.fullName = response.message[0].fullName;
      app.msisdn = response.message[0].msisdn;
      app.email = response.message[0].email;
    },

    async getPages() {
      let app = this;
      let dataObject = {
        route: 'page/view/',
      };
      let response = await this.postJson(dataObject)
      app.pages = response.message;
    },

    async register() {
      let app = this;
      let dataObject = {
        fullName: app.fullName,
        email: app.email,
        msisdn: app.msisdn,
        password: app.password,
        password1: app.password1,
        route: 'user/register/',
      };
      let response = await this.postJson(dataObject)
      if (response.status !== 201){
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.reset();
        app.refresh();
      }
    },

    async updateProfile() {
      let app = this;
      let dataObject = {
        fullName: app.fullName,
        email: app.email,
        msisdn: app.msisdn,
        route: 'user/update/',
      };
      let response = await this.postJson(dataObject)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.reset();
        app.refresh();
      }
    },

    async deactivate(id) {
      let app = this;
      let dataObject = {
        id: id,
        route: 'user/deactivate/',
      };
      let response = await this.postJson(dataObject)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.reset();
        app.refresh();
      }
    },

    async activate(id) {
      let app = this;
      let dataObject = {
        id: id,
        route: 'user/activate/',
      };
      let response = await this.postJson(dataObject)
      if (response.status !== 200){
        swal("Error!", response.message, "error");
      }
      else {
        swal("Success!", response.message, "success");
        app.reset();
        app.refresh();
      }
    },
  },
}
</script>
